/**
 * List out all available block classes on Flexible Content for Tailwind to work
 */

export const marginTopDesktop = (value: number) => {
  const options = [
    'lg:mt-8',
    'lg:mt-14',
    'lg:mt-20',
    'lg:mt-28',
    'lg:mt-36',
    'lg:mt-44',
    'lg:mt-52',
    'lg:mt-60',
    'lg:mt-72',
    'lg:mt-96',
  ]

  return options[value - 1]
}

export const marginBottomDesktop = (value: number) => {
  const options = [
    'lg:mb-8',
    'lg:mb-14',
    'lg:mb-20',
    'lg:mb-28',
    'lg:mb-36',
    'lg:mb-44',
    'lg:mb-52',
    'lg:mb-60',
    'lg:mb-72',
    'lg:mb-96',
  ]

  return options[value - 1]
}

export const paddingTopDesktop = (value: number) => {
  const options = [
    'lg:pt-8',
    'lg:pt-14',
    'lg:pt-20',
    'lg:pt-28',
    'lg:pt-36',
    'lg:pt-44',
    'lg:pt-52',
    'lg:pt-60',
    'lg:pt-72',
    'lg:pt-96',
  ]

  return options[value - 1]
}

export const paddingBottomDesktop = (value: number) => {
  const options = [
    'lg:pb-8',
    'lg:pb-14',
    'lg:pb-20',
    'lg:pb-28',
    'lg:pb-36',
    'lg:pb-44',
    'lg:pb-52',
    'lg:pb-60',
    'lg:pb-72',
    'lg:pb-96',
  ]

  return options[value - 1]
}

export const marginTopMobile = (value: number) => {
  const options = [
    'mt-8',
    'mt-14',
    'mt-20',
    'mt-28',
    'mt-36',
    'mt-44',
    'mt-52',
    'mt-60',
    'mt-72',
    'mt-96',
  ]

  return options[value - 1]
}

export const marginBottomMobile = (value: number) => {
  const options = [
    'mb-8',
    'mb-14',
    'mb-20',
    'mb-28',
    'mb-36',
    'mb-44',
    'mb-52',
    'mb-60',
    'mb-72',
    'mb-96',
  ]

  return options[value - 1]
}

export const paddingTopMobile = (value: number) => {
  const options = [
    'pt-8',
    'pt-14',
    'pt-20',
    'pt-28',
    'pt-36',
    'pt-44',
    'pt-52',
    'pt-60',
    'pt-72',
    'pt-96',
  ]

  return options[value - 1]
}

export const paddingBottomMobile = (value: number) => {
  const options = [
    'pb-8',
    'pb-14',
    'pb-20',
    'pb-28',
    'pb-36',
    'pb-44',
    'pb-52',
    'pb-60',
    'pb-72',
    'pb-96',
  ]

  return options[value - 1]
}

export const backgroundColourClass = (value: string) => {
  const options = [
    { name: 'light-grey', value: 'bg-slate-50' },
    { name: 'medium-grey', value: 'bg-slate-200' },
    { name: 'dark-grey', value: 'bg-slate-800' },
    { name: 'white', value: 'bg-white' },
    { name: 'transparent', value: 'bg-transparent' },
    { name: 'vibrant-green', value: 'bg-vibrant-green' },
    { name: 'light-green', value: 'bg-light-green' },
    { name: 'teal-green', value: 'bg-teal-green' },
    { name: 'forest-green', value: 'bg-forest-green' },
    { name: 'candle-yellow', value: 'bg-candle-yellow' },
    { name: 'amber-300', value: 'bg-amber-300' },
    { name: 'teal-900', value: 'bg-teal-900' },
    { name: 'stone', value: 'bg-[#f1eae2]' },
  ]

  const option = options.find(e => e.name === value)

  if (option) {
    return option.value
  }

  return ''
}
